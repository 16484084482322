.home_hero_section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #30504c;
  height: 80vh;
}
.hero_section_img {
  width: 725px;
  height: 527px;
}
.hero_section_img img {
  width: 100%;
  height: 100%;
}
.hero_section_contain {
  width: 455px;
  display: flex;
  flex-direction: column;
  gap: 26px;
  color: white;
}
.hero_section_contain h1 {
  font-size: 58px;
}
.hero_section_contain_buttons {
  display: flex;
  gap: 15px;
}
.hero_section_contain_buttons button {
  background-color: #30504c;
  color: white;
  border: 1px solid white;
  padding: 15px 30px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 30px;
}
.combine_section {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.combine_section img {
  width: 100%;
  height: 100%;
}
.testimonial_section {
  margin-top: 50px;
}
@media (min-width: 426px) and (max-width: 768px) {
  .hero_section_contain h1 {
    font-size: 40px;
  }

  .hero_section_contain_buttons button {
    padding: 7px 15px;
    font-size: 12px;
  }
  .home_hero_section {
    padding: 15px;
  }
}
@media only screen and (max-width: 426px) {
  .hero_section_contain_buttons button {
    padding: 9px 14px;
    font-size: 10px;
  }
  .hero_section_contain h1 {
    font-size: 30px;
  }
  .home_hero_section {
    height: 60vh;
    padding: 8px;
    flex-direction: column;
  }
  .hero_section_img {
    width: 100%;
    height: 100%;
  }
  .hero_section_contain {
    width: 100%;
    gap: 16px;
  }
  .hero_section_contain_lorem p {
    font-size: 12px;
  }
}
