.mainabb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.formWrapperr {
  height: 350px;
  width: 500px;
  background-color: white;
  border-radius: 30px;
  box-shadow: 2px 3px 10px rgb(183, 182, 182);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 90%;
  align-items: center;
}

.formWrapperr .headingEnn {
  font-size: 40px;
  font-weight: bold;
  color: #391710;
  padding: 10px 0;
}

.formWrapperr .labell {
  font-size: 25px;
  font-weight: bold;
  color: #391710;
  padding: 7px 0;
}

.formWrapperr input {
  width: 70%;
  padding: 10px 20px;
  font-size: 20px;
  height: 30px;
  border-radius: 10px;
}

.formWrapperr select {
  width: 70%;
  padding: 0 20px;
  height: 35px;
  border-radius: 10px;
}

.formWrapperr .button {
  padding: 30px;
}

.formWrapperr button {
  padding: 10px 70px;
  background-color: #391710;
  border-radius: 5px;
  border: none;
  font-size: 20px;
  color: white;
  cursor: pointer;
}

.butwrap {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}

.butwrap button {
  padding: 5px;
  border-radius: 3px;
  background-color: white;
  font-size: 15px;
  border: 1px solid #391710;
  cursor: pointer;
}
