.sofa_design_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
}
.sofa_design_wrapper img {
  height: 400px;
  width: 400px;
}
.sofaimg_heading {
  text-align: center;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 25px;
}
.sofaimg_enquiry {
  width: 100%;
  display: flex;
  justify-content: end;
  height: 150px;
  align-items: center;
}
.sofaimg_enquiry button {
  height: 43px;
  width: 150px;
  margin-right: 25px;
  background-color: #30504c;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
}
@media only screen and (max-width: 426px) {
  .sofa_design_wrapper img {
    height: 100%;
    width: 100%;
    padding: 5px;
  }
  .sofa_design_wrapper {
    gap: 1px;
  }
  .sofaimg_heading {
    font-size: 17px;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .sofa_design_wrapper img {
    height: 300px;
    width: 300px;
  }
}
