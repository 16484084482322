.bed_heading {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 700;
}
.bedimg_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.bedimg_wrapper img {
  width: 400px;
  height: 400px;
}
.bed_button {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100px;
}
.bed_button button {
  height: 43px;
  width: 150px;
  background-color: #30504c;
  color: white;
  margin-right: 45px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 5px;
  outline: none;
  border: none;
}
@media only screen and (max-width: 426px) {
  .bed_heading {
    font-size: 18px;
    text-align: center;
  }
  .bedimg_wrapper img {
    width: 100%;
    height: 100%;
    padding: 8px;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .bedimg_wrapper img {
    width: 300px;
    height: 300px;
  }
}
