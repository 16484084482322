.home_hero_sectionn {
  background-color: #30504c;
  height: 25vh;
}
.hero_section_containn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  color: white;
}
.contact_wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
}
.contact_info {
  height: 350px;
  width: 450px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  gap: 30px;
  filter: drop-shadow(1px 2px 4px rgb(165, 164, 164));
  border-radius: 5px;
}
.information {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.contact_icon {
  display: flex;
  gap: 48px;
}
.map {
  width: 450px;
  height: 350px;
}
@media only screen and (max-width: 426px) {
  .contact_info {
    height: auto;
    width: 100%;
    font-size: 12px;
    margin-top: 15px;
    padding: 5px;
  }
  .contact_wrapper {
    flex-direction: column;
    gap: 10px;
    padding: 5px;
  }
  .map {
    width: 100%;
    height: 300px;
    padding: 5px;
  }
  .home_hero_sectionn {
    background-color: #30504c;
    height: 24vh;
    text-align: center;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .contact_info {
    height: 350px;
    width: 350px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    gap: 30px;
    filter: drop-shadow(1px 2px 4px rgb(165, 164, 164));
    border-radius: 5px;
    font-size: 13px;
  }
  .map {
    width: 350px;
    height: 350px;
    padding: 5px;
  }
}
