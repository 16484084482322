.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: transparent;
  position: relative;
  z-index: 999;
  background-color: #30504c;
}
.header_logo {
  height: 160px;
  width: 150px;
}
.header_logo img {
  width: 100%;
  height: 100%;
}
.header_menu {
  display: flex;
  gap: 120px;
}
.Navlink {
  list-style-type: none;
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 700;
  position: relative;
  cursor: pointer;
}
.active {
  border-bottom: 1px solid rgb(255, 255, 255);
}
.menu_icons {
  display: none;
}
.header_menuu {
  display: flex;
  gap: 120px;
}
.dropdown_content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #30504c;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding: 10px;
  z-index: 10;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown_item {
  color: white;
  text-decoration: none;
  padding: 10px;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.dropdown_item:hover {
  background-color: #3e6e68;
}
@media only screen and (max-width: 426px) {
  .menu_icons {
    display: block;
    color: white;
    font-size: larger;
  }
  .header_menu {
    display: none;
  }
  .header_menuu {
    display: flex;
    flex-direction: column;
    margin-top: 300px;
    height: 250px;
    z-index: 1;
    position: absolute;
    right: 0;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #30504c;
  }
  .Navlink {
    font-size: 16px;
  }
  .header_logo {
    height: 100px;
    width: 110px;
  }
  .dropdown_content {
    min-width: 160px;
    padding: 5px;
    text-align: center;
    background-color: #30504c;
    margin-left: 24px;
    border: 1px solid;
  }
  .dropdown_item {
    padding: 5px;
    font-size: 12px;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .header_menu {
    gap: 34px;
  }
  .header_menuu {
    gap: 34px;
  }
  .Navlink {
    font-size: 17px;
  }
  .header_logo {
    height: 120px;
    width: 130px;
  }
  .dropdown_content {
    min-width: 123px;
    padding: 5px;
    text-align: center;
  }
  .dropdown_item {
    padding: 5px;
    font-size: 14px;
  }
}
