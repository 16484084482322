.new_design_images_wrapper {
  height: auto;
}
.new_design_images {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 90px;
}
.new_design_images img {
  height: 350px;
  width: 350px;
}

.nsofa_enquiry {
  margin-top: 50px;
  text-align: center;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nsofa_enquiry button {
  height: 43px;
  width: 150px;
  background-color: #30504c;
  color: white;
  font-weight: 700;
  cursor: pointer;
}
@media only screen and (max-width: 426px) {
  .new_design_images img {
    height: 100%;
    width: 100%;
    padding: 5px;
  }
  .new_design_images {
    margin-top: 25px;
  }
}
