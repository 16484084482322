.about_section_img {
  width: 100%;
  margin-top: 15px;
  padding: 15px;
}
.about_section_img img {
  height: 100%;
  width: 100%;
}
.about_cards {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
}
.about_card {
  width: 260px;
  text-align: center;
  box-shadow: 1px 2px 10px #dcfbf7;
  padding: 5px;
  border-radius: 19px;
  border: 1px solid #50837c;
  background-color: #fbfffe;
  transition: 0.3s;
}
.about_card:hover {
  transform: scale(1.1);
  transition: 0.3s;
}
.about_card_img {
  height: 120px;
}
.about_card_img img {
  height: 100%;
  width: 100%;
}
.about_card_content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  text-align: center;
}
.mission_vission_container {
  text-align: center;
  display: flex;
  margin-top: 50px;
  padding: 15px;
}
.fs_mission_section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.fs_vission_section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.about_gallery h2 {
  text-align: center;
  margin-top: 30px;
}
.about_gallery_images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}
.about_gallery_images img {
  width: 350px;
}
.testimonal {
  margin-top: 50px;
}
@media only screen and (max-width: 426px) {
  .about_cards {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
  .about_card_img {
    height: 80px;
  }
  .mission_vission_container {
    margin-top: 14px;
    flex-direction: column;
  }
  .about_gallery_images img {
    width: 100%;
    padding: 5px;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .about_cards {
    flex-wrap: wrap;
    gap: 25px;
  }
}
