.choose_heading {
  padding: 15px;
  margin-top: 50px;
}
.choose_wrapper {
  display: flex;
  justify-content: space-around;
  padding: 15px;
}
.contain_side {
  width: 570px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.four_contain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 520px;
  height: 470px;
  gap: 40px;
}
.first_choose_contain {
  width: 260px;
}
.choose_image_side {
  height: 500px;
  width: 650px;
  /* background-color: aqua; */
}
.choose_image_side img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (min-width: 426px) and (max-width: 768px) {
  .choose_wrapper {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 426px) {
  .four_contain {
    grid-template-columns: 1fr;
    width: 100%;
    height: 100%;
    gap: 27px;
    text-align: center;
  }
  .choose_wrapper {
    flex-direction: column;
  }
  .contain_side {
    width: 100%;
  }
  .choose_image_side {
    height: 100%;
    width: 100%;
  }
  .first_choose_contain {
    width: 100%;
  }
}
