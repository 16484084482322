.poster_center {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  margin-top: 25px;
}
.our_work_heading {
  text-align: center;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.our_work_images {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 25px;
  padding: 8px;
}

.our_work_images img {
  width: 350px;
  transition: 0.3s;
}

.our_work_images img:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.repairing_work_heading {
  text-align: center;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.repair_images_poster_wrapper {
  height: 493px;
  width: 550px;
  /* background: #000; */
  position: relative;
}
.repair_images_poster img {
  width: 100%;
}

.before,
.after {
  width: 450px;
  position: absolute;
}
.before {
  top: 116px;
  left: 39px;
  height: 268px;
  width: 235px;
}
.after {
  top: 116px;
  left: 280px;
  height: 269px;
  width: 234px;
}
@media only screen and (max-width: 426px) {
  .repair_images_poster_wrapper {
    height: 100%;
    width: 100%;
    /* background: #000; */
    position: relative;
  }
  .our_work_images img {
    width: 100%;
    transition: 0.3s;
  }
  .before {
    width: 44%;
    top: 23%;
    left: 5%;
    height: 54%;
  }
  .after {
    width: 46%;
    top: 23%;
    left: 50%;
    height: 54%;
  }
}
